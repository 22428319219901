import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _adc17ebc = () => interopDefault(import('../src/pages/activities.vue' /* webpackChunkName: "pages/activities" */))
const _36e04233 = () => interopDefault(import('../src/pages/activities/new.vue' /* webpackChunkName: "pages/activities/new" */))
const _232a6d4d = () => interopDefault(import('../src/pages/activities/_id.vue' /* webpackChunkName: "pages/activities/_id" */))
const _27f34273 = () => interopDefault(import('../src/pages/feeds/index.vue' /* webpackChunkName: "pages/feeds/index" */))
const _52ba3ee4 = () => interopDefault(import('../src/pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _1dbacc4f = () => interopDefault(import('../src/pages/jobs_search/index.vue' /* webpackChunkName: "pages/jobs_search/index" */))
const _1d1ec1c1 = () => interopDefault(import('../src/pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _c9937228 = () => interopDefault(import('../src/pages/messages/_id.vue' /* webpackChunkName: "pages/messages/_id" */))
const _2b7ee1c3 = () => interopDefault(import('../src/pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _69cdba73 = () => interopDefault(import('../src/pages/notifications/messages/_id.vue' /* webpackChunkName: "pages/notifications/messages/_id" */))
const _67f7af73 = () => interopDefault(import('../src/pages/notifications/notice_boards/_id.vue' /* webpackChunkName: "pages/notifications/notice_boards/_id" */))
const _217358be = () => interopDefault(import('../src/pages/notifications/offers/_id.vue' /* webpackChunkName: "pages/notifications/offers/_id" */))
const _3ca295ee = () => interopDefault(import('../src/pages/notifications/_id.vue' /* webpackChunkName: "pages/notifications/_id" */))
const _9371b068 = () => interopDefault(import('../src/pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _eed58dd0 = () => interopDefault(import('../src/pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _0b56a253 = () => interopDefault(import('../src/pages/settings/privacy_policy/index.vue' /* webpackChunkName: "pages/settings/privacy_policy/index" */))
const _4d4a8fa1 = () => interopDefault(import('../src/pages/settings/terms_of_use/index.vue' /* webpackChunkName: "pages/settings/terms_of_use/index" */))
const _217c649e = () => interopDefault(import('../src/pages/settings/activity_status/edit.vue' /* webpackChunkName: "pages/settings/activity_status/edit" */))
const _165780e5 = () => interopDefault(import('../src/pages/settings/desired_conditions/edit.vue' /* webpackChunkName: "pages/settings/desired_conditions/edit" */))
const _7ecc5c24 = () => interopDefault(import('../src/pages/settings/email/edit.vue' /* webpackChunkName: "pages/settings/email/edit" */))
const _7bb7c6aa = () => interopDefault(import('../src/pages/settings/language/edit.vue' /* webpackChunkName: "pages/settings/language/edit" */))
const _5f55ca72 = () => interopDefault(import('../src/pages/settings/password/edit.vue' /* webpackChunkName: "pages/settings/password/edit" */))
const _a9ea540a = () => interopDefault(import('../src/pages/settings/social_links/edit.vue' /* webpackChunkName: "pages/settings/social_links/edit" */))
const _7c8f47ce = () => interopDefault(import('../src/pages/app/launch/index.vue' /* webpackChunkName: "pages/app/launch/index" */))
const _5e9527f6 = () => interopDefault(import('../src/pages/bypass/gone_seniors.vue' /* webpackChunkName: "pages/bypass/gone_seniors" */))
const _d2e68196 = () => interopDefault(import('../src/pages/bypass/gone_sps_list.vue' /* webpackChunkName: "pages/bypass/gone_sps_list" */))
const _f19f6842 = () => interopDefault(import('../src/pages/bypass/recommend_other_browsers.vue' /* webpackChunkName: "pages/bypass/recommend_other_browsers" */))
const _6c8e2ae4 = () => interopDefault(import('../src/pages/feeds/categories/index.vue' /* webpackChunkName: "pages/feeds/categories/index" */))
const _0d4fec2a = () => interopDefault(import('../src/pages/feeds/corps.vue' /* webpackChunkName: "pages/feeds/corps" */))
const _6bbbe4e7 = () => interopDefault(import('../src/pages/feeds/lesson.vue' /* webpackChunkName: "pages/feeds/lesson" */))
const _345eb324 = () => interopDefault(import('../src/pages/feeds/seniors.vue' /* webpackChunkName: "pages/feeds/seniors" */))
const _75cf47ef = () => interopDefault(import('../src/pages/jobs_search/categories.vue' /* webpackChunkName: "pages/jobs_search/categories" */))
const _2f7b341c = () => interopDefault(import('../src/pages/jobs_search/cities.vue' /* webpackChunkName: "pages/jobs_search/cities" */))
const _6dbbd424 = () => interopDefault(import('../src/pages/jobs_search/corporation_type.vue' /* webpackChunkName: "pages/jobs_search/corporation_type" */))
const _09a1a4cf = () => interopDefault(import('../src/pages/jobs_search/industries.vue' /* webpackChunkName: "pages/jobs_search/industries" */))
const _0230d121 = () => interopDefault(import('../src/pages/jobs_search/internship.vue' /* webpackChunkName: "pages/jobs_search/internship" */))
const _2464199e = () => interopDefault(import('../src/pages/jobs_search/job_feature.vue' /* webpackChunkName: "pages/jobs_search/job_feature" */))
const _315c5a4a = () => interopDefault(import('../src/pages/jobs_search/prefectures.vue' /* webpackChunkName: "pages/jobs_search/prefectures" */))
const _1f9fdca4 = () => interopDefault(import('../src/pages/jobs_search/seminar.vue' /* webpackChunkName: "pages/jobs_search/seminar" */))
const _5f1706e4 = () => interopDefault(import('../src/pages/jobs/internships/index.vue' /* webpackChunkName: "pages/jobs/internships/index" */))
const _21ce1299 = () => interopDefault(import('../src/pages/jobs/seminars/index.vue' /* webpackChunkName: "pages/jobs/seminars/index" */))
const _651f4511 = () => interopDefault(import('../src/pages/users/confirmation.vue' /* webpackChunkName: "pages/users/confirmation" */))
const _0b14b2bb = () => interopDefault(import('../src/pages/users/sign_in.vue' /* webpackChunkName: "pages/users/sign_in" */))
const _b6daa5b0 = () => interopDefault(import('../src/pages/users/sign_out.vue' /* webpackChunkName: "pages/users/sign_out" */))
const _8143b218 = () => interopDefault(import('../src/pages/users/sign_up/index.vue' /* webpackChunkName: "pages/users/sign_up/index" */))
const _7b2516e0 = () => interopDefault(import('../src/pages/users/confirmations/reconfirm.vue' /* webpackChunkName: "pages/users/confirmations/reconfirm" */))
const _13bb6052 = () => interopDefault(import('../src/pages/users/password/edit.vue' /* webpackChunkName: "pages/users/password/edit" */))
const _c5f43cf0 = () => interopDefault(import('../src/pages/users/password/new.vue' /* webpackChunkName: "pages/users/password/new" */))
const _d0fc00dc = () => interopDefault(import('../src/pages/users/sign_up/form.vue' /* webpackChunkName: "pages/users/sign_up/form" */))
const _1664a3b4 = () => interopDefault(import('../src/pages/my_page/profile/aptitude_diagnosis/new/index.vue' /* webpackChunkName: "pages/my_page/profile/aptitude_diagnosis/new/index" */))
const _d7fdd848 = () => interopDefault(import('../src/pages/my_page/profile/aptitude_diagnosis/result.vue' /* webpackChunkName: "pages/my_page/profile/aptitude_diagnosis/result" */))
const _569434a3 = () => interopDefault(import('../src/pages/my_page/profile/base_information/edit.vue' /* webpackChunkName: "pages/my_page/profile/base_information/edit" */))
const _4b51d141 = () => interopDefault(import('../src/pages/my_page/profile/portfolio/new.vue' /* webpackChunkName: "pages/my_page/profile/portfolio/new" */))
const _77f02920 = () => interopDefault(import('../src/pages/my_page/profile/public_relations/edit.vue' /* webpackChunkName: "pages/my_page/profile/public_relations/edit" */))
const _7b1ff9c8 = () => interopDefault(import('../src/pages/my_page/profile/aptitude_diagnosis/new/_id.vue' /* webpackChunkName: "pages/my_page/profile/aptitude_diagnosis/new/_id" */))
const _546f7dea = () => interopDefault(import('../src/pages/my_page/profile/aptitude_diagnosis/_id.vue' /* webpackChunkName: "pages/my_page/profile/aptitude_diagnosis/_id" */))
const _5b52929e = () => interopDefault(import('../src/pages/my_page/profile/portfolio/_id/index.vue' /* webpackChunkName: "pages/my_page/profile/portfolio/_id/index" */))
const _2a1dc4ee = () => interopDefault(import('../src/pages/my_page/profile/portfolio/_id/edit.vue' /* webpackChunkName: "pages/my_page/profile/portfolio/_id/edit" */))
const _b0078f14 = () => interopDefault(import('../src/pages/feeds/categories/_id.vue' /* webpackChunkName: "pages/feeds/categories/_id" */))
const _6d471fec = () => interopDefault(import('../src/pages/feeds/senior/_id.vue' /* webpackChunkName: "pages/feeds/senior/_id" */))
const _1290ded2 = () => interopDefault(import('../src/pages/feeds/sort/_type.vue' /* webpackChunkName: "pages/feeds/sort/_type" */))
const _58ebe062 = () => interopDefault(import('../src/pages/jobs/internships/_id/index.vue' /* webpackChunkName: "pages/jobs/internships/_id/index" */))
const _07ba3904 = () => interopDefault(import('../src/pages/jobs/seminars/_id/index.vue' /* webpackChunkName: "pages/jobs/seminars/_id/index" */))
const _0f5e73da = () => interopDefault(import('../src/pages/jobs/show_corp/_id.vue' /* webpackChunkName: "pages/jobs/show_corp/_id" */))
const _b0192b32 = () => interopDefault(import('../src/pages/my_page/offers/_id/index.vue' /* webpackChunkName: "pages/my_page/offers/_id/index" */))
const _31cdc122 = () => interopDefault(import('../src/pages/jobs/internships/_id/entry.vue' /* webpackChunkName: "pages/jobs/internships/_id/entry" */))
const _1b4948a4 = () => interopDefault(import('../src/pages/jobs/seminars/_id/entry.vue' /* webpackChunkName: "pages/jobs/seminars/_id/entry" */))
const _28d1bcea = () => interopDefault(import('../src/pages/corporations/_id.vue' /* webpackChunkName: "pages/corporations/_id" */))
const _3007bfde = () => interopDefault(import('../src/pages/corporations/_id/feeds.vue' /* webpackChunkName: "pages/corporations/_id/feeds" */))
const _7184c60a = () => interopDefault(import('../src/pages/corporations/_id/jobs.vue' /* webpackChunkName: "pages/corporations/_id/jobs" */))
const _4d3e6f95 = () => interopDefault(import('../src/pages/corporations/_id/members.vue' /* webpackChunkName: "pages/corporations/_id/members" */))
const _177574ca = () => interopDefault(import('../src/pages/feeds/_id.vue' /* webpackChunkName: "pages/feeds/_id" */))
const _ee45f70e = () => interopDefault(import('../src/pages/jobs/_id/index.vue' /* webpackChunkName: "pages/jobs/_id/index" */))
const _1b434e88 = () => interopDefault(import('../src/pages/my_page/_id.vue' /* webpackChunkName: "pages/my_page/_id" */))
const _9ce23b94 = () => interopDefault(import('../src/pages/my_page/_id/bookmarks.vue' /* webpackChunkName: "pages/my_page/_id/bookmarks" */))
const _0eefb5cd = () => interopDefault(import('../src/pages/my_page/_id/notice_boards.vue' /* webpackChunkName: "pages/my_page/_id/notice_boards" */))
const _5533282e = () => interopDefault(import('../src/pages/my_page/_id/offers.vue' /* webpackChunkName: "pages/my_page/_id/offers" */))
const _12ad4822 = () => interopDefault(import('../src/pages/my_page/_id/profile.vue' /* webpackChunkName: "pages/my_page/_id/profile" */))
const _77fddf9a = () => interopDefault(import('../src/pages/recruit/_page_/index.vue' /* webpackChunkName: "pages/recruit/_page_/index" */))
const _24c6f9f2 = () => interopDefault(import('../src/pages/company_visit/_id/entry.vue' /* webpackChunkName: "pages/company_visit/_id/entry" */))
const _c727d7ce = () => interopDefault(import('../src/pages/jobs/_id/entry.vue' /* webpackChunkName: "pages/jobs/_id/entry" */))
const _b1255ce6 = () => interopDefault(import('../src/pages/jobs/_id/f.vue' /* webpackChunkName: "pages/jobs/_id/f" */))
const _b00b86d2 = () => interopDefault(import('../src/pages/jobs/_id/p.vue' /* webpackChunkName: "pages/jobs/_id/p" */))
const _68374e3e = () => interopDefault(import('../src/pages/corporations/_corporationId/graduates/_memberId.vue' /* webpackChunkName: "pages/corporations/_corporationId/graduates/_memberId" */))
const _41230ab2 = () => interopDefault(import('../src/pages/corporations/_corporationId/members/_memberId.vue' /* webpackChunkName: "pages/corporations/_corporationId/members/_memberId" */))
const _e831ae3c = () => interopDefault(import('../src/pages/my_page/_notice_boards/_id.vue' /* webpackChunkName: "pages/my_page/_notice_boards/_id" */))
const _30d30082 = () => interopDefault(import('../src/pages/recruit/_page_/_id.vue' /* webpackChunkName: "pages/recruit/_page_/_id" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2632eff0 = () => interopDefault(import('../src/pages/_extjobs/index.vue' /* webpackChunkName: "pages/_extjobs/index" */))
const _a6c7a350 = () => interopDefault(import('../src/pages/_extjobs/_id.vue' /* webpackChunkName: "pages/_extjobs/_id" */))

const _dfe8852a = () => interopDefault(import('../src/pages/my_page/profile/aptitude_diagnosis/new/_id.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activities",
    component: _adc17ebc,
    name: "activities___ja___default",
    children: [{
      path: "new",
      component: _36e04233,
      name: "activities-new___ja___default"
    }, {
      path: ":id?",
      component: _232a6d4d,
      name: "activities-id___ja___default"
    }]
  }, {
    path: "/ja/activities",
    component: _adc17ebc,
    name: "activities___ja",
    children: [{
      path: "new",
      component: _36e04233,
      name: "activities-new___ja"
    }, {
      path: ":id?",
      component: _232a6d4d,
      name: "activities-id___ja"
    }]
  }, {
    path: "/en/activities",
    component: _adc17ebc,
    name: "activities___en",
    children: [{
      path: "new",
      component: _36e04233,
      name: "activities-new___en"
    }, {
      path: ":id?",
      component: _232a6d4d,
      name: "activities-id___en"
    }]
  }, {
    path: "/feeds",
    component: _27f34273,
    name: "feeds___ja___default"
  }, {
    path: "/ja/feeds",
    component: _27f34273,
    name: "feeds___ja"
  }, {
    path: "/en/feeds",
    component: _27f34273,
    name: "feeds___en"
  }, {
    path: "/jobs",
    component: _52ba3ee4,
    name: "jobs___ja___default"
  }, {
    path: "/ja/jobs",
    component: _52ba3ee4,
    name: "jobs___ja"
  }, {
    path: "/en/jobs",
    component: _52ba3ee4,
    name: "jobs___en"
  }, {
    path: "/jobs_search",
    component: _1dbacc4f,
    name: "jobs_search___ja___default"
  }, {
    path: "/ja/jobs_search",
    component: _1dbacc4f,
    name: "jobs_search___ja"
  }, {
    path: "/en/jobs_search",
    component: _1dbacc4f,
    name: "jobs_search___en"
  }, {
    path: "/messages",
    component: _1d1ec1c1,
    name: "messages___ja___default",
    children: [{
      path: ":id?",
      component: _c9937228,
      name: "messages-id___ja___default"
    }]
  }, {
    path: "/ja/messages",
    component: _1d1ec1c1,
    name: "messages___ja",
    children: [{
      path: ":id?",
      component: _c9937228,
      name: "messages-id___ja"
    }]
  }, {
    path: "/en/messages",
    component: _1d1ec1c1,
    name: "messages___en",
    children: [{
      path: ":id?",
      component: _c9937228,
      name: "messages-id___en"
    }]
  }, {
    path: "/notifications",
    component: _2b7ee1c3,
    name: "notifications___ja___default",
    children: [{
      path: "messages/:id?",
      component: _69cdba73,
      name: "notifications-messages-id___ja___default"
    }, {
      path: "notice_boards/:id?",
      component: _67f7af73,
      name: "notifications-notice_boards-id___ja___default"
    }, {
      path: "offers/:id?",
      component: _217358be,
      name: "notifications-offers-id___ja___default"
    }, {
      path: ":id?",
      component: _3ca295ee,
      name: "notifications-id___ja___default"
    }]
  }, {
    path: "/ja/notifications",
    component: _2b7ee1c3,
    name: "notifications___ja",
    children: [{
      path: "messages/:id?",
      component: _69cdba73,
      name: "notifications-messages-id___ja"
    }, {
      path: "notice_boards/:id?",
      component: _67f7af73,
      name: "notifications-notice_boards-id___ja"
    }, {
      path: "offers/:id?",
      component: _217358be,
      name: "notifications-offers-id___ja"
    }, {
      path: ":id?",
      component: _3ca295ee,
      name: "notifications-id___ja"
    }]
  }, {
    path: "/en/notifications",
    component: _2b7ee1c3,
    name: "notifications___en",
    children: [{
      path: "messages/:id?",
      component: _69cdba73,
      name: "notifications-messages-id___en"
    }, {
      path: "notice_boards/:id?",
      component: _67f7af73,
      name: "notifications-notice_boards-id___en"
    }, {
      path: "offers/:id?",
      component: _217358be,
      name: "notifications-offers-id___en"
    }, {
      path: ":id?",
      component: _3ca295ee,
      name: "notifications-id___en"
    }]
  }, {
    path: "/offers",
    component: _9371b068,
    name: "offers___ja___default"
  }, {
    path: "/ja/offers",
    component: _9371b068,
    name: "offers___ja"
  }, {
    path: "/en/offers",
    component: _9371b068,
    name: "offers___en"
  }, {
    path: "/settings",
    component: _eed58dd0,
    name: "settings___ja___default",
    children: [{
      path: "privacy_policy",
      component: _0b56a253,
      name: "settings-privacy_policy___ja___default"
    }, {
      path: "terms_of_use",
      component: _4d4a8fa1,
      name: "settings-terms_of_use___ja___default"
    }, {
      path: "activity_status/edit",
      component: _217c649e,
      name: "settings-activity_status-edit___ja___default"
    }, {
      path: "desired_conditions/edit",
      component: _165780e5,
      name: "settings-desired_conditions-edit___ja___default"
    }, {
      path: "email/edit",
      component: _7ecc5c24,
      name: "settings-email-edit___ja___default"
    }, {
      path: "language/edit",
      component: _7bb7c6aa,
      name: "settings-language-edit___ja___default"
    }, {
      path: "password/edit",
      component: _5f55ca72,
      name: "settings-password-edit___ja___default"
    }, {
      path: "social_links/edit",
      component: _a9ea540a,
      name: "settings-social_links-edit___ja___default"
    }]
  }, {
    path: "/ja/settings",
    component: _eed58dd0,
    name: "settings___ja",
    children: [{
      path: "privacy_policy",
      component: _0b56a253,
      name: "settings-privacy_policy___ja"
    }, {
      path: "terms_of_use",
      component: _4d4a8fa1,
      name: "settings-terms_of_use___ja"
    }, {
      path: "activity_status/edit",
      component: _217c649e,
      name: "settings-activity_status-edit___ja"
    }, {
      path: "desired_conditions/edit",
      component: _165780e5,
      name: "settings-desired_conditions-edit___ja"
    }, {
      path: "email/edit",
      component: _7ecc5c24,
      name: "settings-email-edit___ja"
    }, {
      path: "language/edit",
      component: _7bb7c6aa,
      name: "settings-language-edit___ja"
    }, {
      path: "password/edit",
      component: _5f55ca72,
      name: "settings-password-edit___ja"
    }, {
      path: "social_links/edit",
      component: _a9ea540a,
      name: "settings-social_links-edit___ja"
    }]
  }, {
    path: "/en/settings",
    component: _eed58dd0,
    name: "settings___en",
    children: [{
      path: "privacy_policy",
      component: _0b56a253,
      name: "settings-privacy_policy___en"
    }, {
      path: "terms_of_use",
      component: _4d4a8fa1,
      name: "settings-terms_of_use___en"
    }, {
      path: "activity_status/edit",
      component: _217c649e,
      name: "settings-activity_status-edit___en"
    }, {
      path: "desired_conditions/edit",
      component: _165780e5,
      name: "settings-desired_conditions-edit___en"
    }, {
      path: "email/edit",
      component: _7ecc5c24,
      name: "settings-email-edit___en"
    }, {
      path: "language/edit",
      component: _7bb7c6aa,
      name: "settings-language-edit___en"
    }, {
      path: "password/edit",
      component: _5f55ca72,
      name: "settings-password-edit___en"
    }, {
      path: "social_links/edit",
      component: _a9ea540a,
      name: "settings-social_links-edit___en"
    }]
  }, {
    path: "/app/launch",
    component: _7c8f47ce,
    name: "app-launch___ja___default"
  }, {
    path: "/ja/app/launch",
    component: _7c8f47ce,
    name: "app-launch___ja"
  }, {
    path: "/en/app/launch",
    component: _7c8f47ce,
    name: "app-launch___en"
  }, {
    path: "/bypass/gone_seniors",
    component: _5e9527f6,
    name: "bypass-gone_seniors___ja___default"
  }, {
    path: "/ja/bypass/gone_seniors",
    component: _5e9527f6,
    name: "bypass-gone_seniors___ja"
  }, {
    path: "/en/bypass/gone_seniors",
    component: _5e9527f6,
    name: "bypass-gone_seniors___en"
  }, {
    path: "/bypass/gone_sps_list",
    component: _d2e68196,
    name: "bypass-gone_sps_list___ja___default"
  }, {
    path: "/ja/bypass/gone_sps_list",
    component: _d2e68196,
    name: "bypass-gone_sps_list___ja"
  }, {
    path: "/en/bypass/gone_sps_list",
    component: _d2e68196,
    name: "bypass-gone_sps_list___en"
  }, {
    path: "/bypass/recommend_other_browsers",
    component: _f19f6842,
    name: "bypass-recommend_other_browsers___ja___default"
  }, {
    path: "/ja/bypass/recommend_other_browsers",
    component: _f19f6842,
    name: "bypass-recommend_other_browsers___ja"
  }, {
    path: "/en/bypass/recommend_other_browsers",
    component: _f19f6842,
    name: "bypass-recommend_other_browsers___en"
  }, {
    path: "/feeds/categories",
    component: _6c8e2ae4,
    name: "feeds-categories___ja___default"
  }, {
    path: "/ja/feeds/categories",
    component: _6c8e2ae4,
    name: "feeds-categories___ja"
  }, {
    path: "/en/feeds/categories",
    component: _6c8e2ae4,
    name: "feeds-categories___en"
  }, {
    path: "/feeds/corps",
    component: _0d4fec2a,
    name: "feeds-corps___ja___default"
  }, {
    path: "/ja/feeds/corps",
    component: _0d4fec2a,
    name: "feeds-corps___ja"
  }, {
    path: "/en/feeds/corps",
    component: _0d4fec2a,
    name: "feeds-corps___en"
  }, {
    path: "/feeds/lesson",
    component: _6bbbe4e7,
    name: "feeds-lesson___ja___default"
  }, {
    path: "/ja/feeds/lesson",
    component: _6bbbe4e7,
    name: "feeds-lesson___ja"
  }, {
    path: "/en/feeds/lesson",
    component: _6bbbe4e7,
    name: "feeds-lesson___en"
  }, {
    path: "/feeds/seniors",
    component: _345eb324,
    name: "feeds-seniors___ja___default"
  }, {
    path: "/ja/feeds/seniors",
    component: _345eb324,
    name: "feeds-seniors___ja"
  }, {
    path: "/en/feeds/seniors",
    component: _345eb324,
    name: "feeds-seniors___en"
  }, {
    path: "/jobs_search/categories",
    component: _75cf47ef,
    name: "jobs_search-categories___ja___default"
  }, {
    path: "/ja/jobs_search/categories",
    component: _75cf47ef,
    name: "jobs_search-categories___ja"
  }, {
    path: "/en/jobs_search/categories",
    component: _75cf47ef,
    name: "jobs_search-categories___en"
  }, {
    path: "/jobs_search/cities",
    component: _2f7b341c,
    name: "jobs_search-cities___ja___default"
  }, {
    path: "/ja/jobs_search/cities",
    component: _2f7b341c,
    name: "jobs_search-cities___ja"
  }, {
    path: "/en/jobs_search/cities",
    component: _2f7b341c,
    name: "jobs_search-cities___en"
  }, {
    path: "/jobs_search/corporation_type",
    component: _6dbbd424,
    name: "jobs_search-corporation_type___ja___default"
  }, {
    path: "/ja/jobs_search/corporation_type",
    component: _6dbbd424,
    name: "jobs_search-corporation_type___ja"
  }, {
    path: "/en/jobs_search/corporation_type",
    component: _6dbbd424,
    name: "jobs_search-corporation_type___en"
  }, {
    path: "/jobs_search/industries",
    component: _09a1a4cf,
    name: "jobs_search-industries___ja___default"
  }, {
    path: "/ja/jobs_search/industries",
    component: _09a1a4cf,
    name: "jobs_search-industries___ja"
  }, {
    path: "/en/jobs_search/industries",
    component: _09a1a4cf,
    name: "jobs_search-industries___en"
  }, {
    path: "/jobs_search/internship",
    component: _0230d121,
    name: "jobs_search-internship___ja___default"
  }, {
    path: "/ja/jobs_search/internship",
    component: _0230d121,
    name: "jobs_search-internship___ja"
  }, {
    path: "/en/jobs_search/internship",
    component: _0230d121,
    name: "jobs_search-internship___en"
  }, {
    path: "/jobs_search/job_feature",
    component: _2464199e,
    name: "jobs_search-job_feature___ja___default"
  }, {
    path: "/ja/jobs_search/job_feature",
    component: _2464199e,
    name: "jobs_search-job_feature___ja"
  }, {
    path: "/en/jobs_search/job_feature",
    component: _2464199e,
    name: "jobs_search-job_feature___en"
  }, {
    path: "/jobs_search/prefectures",
    component: _315c5a4a,
    name: "jobs_search-prefectures___ja___default"
  }, {
    path: "/ja/jobs_search/prefectures",
    component: _315c5a4a,
    name: "jobs_search-prefectures___ja"
  }, {
    path: "/en/jobs_search/prefectures",
    component: _315c5a4a,
    name: "jobs_search-prefectures___en"
  }, {
    path: "/jobs_search/seminar",
    component: _1f9fdca4,
    name: "jobs_search-seminar___ja___default"
  }, {
    path: "/ja/jobs_search/seminar",
    component: _1f9fdca4,
    name: "jobs_search-seminar___ja"
  }, {
    path: "/en/jobs_search/seminar",
    component: _1f9fdca4,
    name: "jobs_search-seminar___en"
  }, {
    path: "/jobs/internships",
    component: _5f1706e4,
    name: "jobs-internships___ja___default"
  }, {
    path: "/ja/jobs/internships",
    component: _5f1706e4,
    name: "jobs-internships___ja"
  }, {
    path: "/en/jobs/internships",
    component: _5f1706e4,
    name: "jobs-internships___en"
  }, {
    path: "/jobs/seminars",
    component: _21ce1299,
    name: "jobs-seminars___ja___default"
  }, {
    path: "/ja/jobs/seminars",
    component: _21ce1299,
    name: "jobs-seminars___ja"
  }, {
    path: "/en/jobs/seminars",
    component: _21ce1299,
    name: "jobs-seminars___en"
  }, {
    path: "/users/confirmation",
    component: _651f4511,
    name: "users-confirmation___ja___default"
  }, {
    path: "/ja/users/confirmation",
    component: _651f4511,
    name: "users-confirmation___ja"
  }, {
    path: "/en/users/confirmation",
    component: _651f4511,
    name: "users-confirmation___en"
  }, {
    path: "/users/sign_in",
    component: _0b14b2bb,
    name: "users-sign_in___ja___default"
  }, {
    path: "/ja/users/sign_in",
    component: _0b14b2bb,
    name: "users-sign_in___ja"
  }, {
    path: "/en/users/sign_in",
    component: _0b14b2bb,
    name: "users-sign_in___en"
  }, {
    path: "/users/sign_out",
    component: _b6daa5b0,
    name: "users-sign_out___ja___default"
  }, {
    path: "/ja/users/sign_out",
    component: _b6daa5b0,
    name: "users-sign_out___ja"
  }, {
    path: "/en/users/sign_out",
    component: _b6daa5b0,
    name: "users-sign_out___en"
  }, {
    path: "/users/sign_up",
    component: _8143b218,
    name: "users-sign_up___ja___default"
  }, {
    path: "/ja/users/sign_up",
    component: _8143b218,
    name: "users-sign_up___ja"
  }, {
    path: "/en/users/sign_up",
    component: _8143b218,
    name: "users-sign_up___en"
  }, {
    path: "/users/confirmations/reconfirm",
    component: _7b2516e0,
    name: "users-confirmations-reconfirm___ja___default"
  }, {
    path: "/ja/users/confirmations/reconfirm",
    component: _7b2516e0,
    name: "users-confirmations-reconfirm___ja"
  }, {
    path: "/en/users/confirmations/reconfirm",
    component: _7b2516e0,
    name: "users-confirmations-reconfirm___en"
  }, {
    path: "/users/password/edit",
    component: _13bb6052,
    name: "users-password-edit___ja___default"
  }, {
    path: "/ja/users/password/edit",
    component: _13bb6052,
    name: "users-password-edit___ja"
  }, {
    path: "/en/users/password/edit",
    component: _13bb6052,
    name: "users-password-edit___en"
  }, {
    path: "/users/password/new",
    component: _c5f43cf0,
    name: "users-password-new___ja___default"
  }, {
    path: "/ja/users/password/new",
    component: _c5f43cf0,
    name: "users-password-new___ja"
  }, {
    path: "/en/users/password/new",
    component: _c5f43cf0,
    name: "users-password-new___en"
  }, {
    path: "/users/sign_up/form",
    component: _d0fc00dc,
    name: "users-sign_up-form___ja___default"
  }, {
    path: "/ja/users/sign_up/form",
    component: _d0fc00dc,
    name: "users-sign_up-form___ja"
  }, {
    path: "/en/users/sign_up/form",
    component: _d0fc00dc,
    name: "users-sign_up-form___en"
  }, {
    path: "/my_page/profile/aptitude_diagnosis/new",
    component: _1664a3b4,
    name: "my_page-profile-aptitude_diagnosis-new___ja___default"
  }, {
    path: "/ja/my_page/profile/aptitude_diagnosis/new",
    component: _1664a3b4,
    name: "my_page-profile-aptitude_diagnosis-new___ja"
  }, {
    path: "/en/my_page/profile/aptitude_diagnosis/new",
    component: _1664a3b4,
    name: "my_page-profile-aptitude_diagnosis-new___en"
  }, {
    path: "/my_page/profile/aptitude_diagnosis/result",
    component: _d7fdd848,
    name: "my_page-profile-aptitude_diagnosis-result___ja___default"
  }, {
    path: "/ja/my_page/profile/aptitude_diagnosis/result",
    component: _d7fdd848,
    name: "my_page-profile-aptitude_diagnosis-result___ja"
  }, {
    path: "/en/my_page/profile/aptitude_diagnosis/result",
    component: _d7fdd848,
    name: "my_page-profile-aptitude_diagnosis-result___en"
  }, {
    path: "/my_page/profile/base_information/edit",
    component: _569434a3,
    name: "my_page-profile-base_information-edit___ja___default"
  }, {
    path: "/ja/my_page/profile/base_information/edit",
    component: _569434a3,
    name: "my_page-profile-base_information-edit___ja"
  }, {
    path: "/en/my_page/profile/base_information/edit",
    component: _569434a3,
    name: "my_page-profile-base_information-edit___en"
  }, {
    path: "/my_page/profile/portfolio/new",
    component: _4b51d141,
    name: "my_page-profile-portfolio-new___ja___default"
  }, {
    path: "/ja/my_page/profile/portfolio/new",
    component: _4b51d141,
    name: "my_page-profile-portfolio-new___ja"
  }, {
    path: "/en/my_page/profile/portfolio/new",
    component: _4b51d141,
    name: "my_page-profile-portfolio-new___en"
  }, {
    path: "/my_page/profile/public_relations/edit",
    component: _77f02920,
    name: "my_page-profile-public_relations-edit___ja___default"
  }, {
    path: "/ja/my_page/profile/public_relations/edit",
    component: _77f02920,
    name: "my_page-profile-public_relations-edit___ja"
  }, {
    path: "/en/my_page/profile/public_relations/edit",
    component: _77f02920,
    name: "my_page-profile-public_relations-edit___en"
  }, {
    path: "/my_page/profile/aptitude_diagnosis/new/:id",
    component: _7b1ff9c8,
    name: "my_page-profile-aptitude_diagnosis-new-id___ja___default"
  }, {
    path: "/ja/my_page/profile/aptitude_diagnosis/new/:id",
    component: _7b1ff9c8,
    name: "my_page-profile-aptitude_diagnosis-new-id___ja"
  }, {
    path: "/en/my_page/profile/aptitude_diagnosis/new/:id",
    component: _7b1ff9c8,
    name: "my_page-profile-aptitude_diagnosis-new-id___en"
  }, {
    path: "/my_page/profile/aptitude_diagnosis/:id?",
    component: _546f7dea,
    name: "my_page-profile-aptitude_diagnosis-id___ja___default"
  }, {
    path: "/ja/my_page/profile/aptitude_diagnosis/:id?",
    component: _546f7dea,
    name: "my_page-profile-aptitude_diagnosis-id___ja"
  }, {
    path: "/en/my_page/profile/aptitude_diagnosis/:id?",
    component: _546f7dea,
    name: "my_page-profile-aptitude_diagnosis-id___en"
  }, {
    path: "/my_page/profile/portfolio/:id",
    component: _5b52929e,
    name: "my_page-profile-portfolio-id___ja___default"
  }, {
    path: "/ja/my_page/profile/portfolio/:id",
    component: _5b52929e,
    name: "my_page-profile-portfolio-id___ja"
  }, {
    path: "/en/my_page/profile/portfolio/:id",
    component: _5b52929e,
    name: "my_page-profile-portfolio-id___en"
  }, {
    path: "/my_page/profile/portfolio/:id?/edit",
    component: _2a1dc4ee,
    name: "my_page-profile-portfolio-id-edit___ja___default"
  }, {
    path: "/ja/my_page/profile/portfolio/:id?/edit",
    component: _2a1dc4ee,
    name: "my_page-profile-portfolio-id-edit___ja"
  }, {
    path: "/en/my_page/profile/portfolio/:id?/edit",
    component: _2a1dc4ee,
    name: "my_page-profile-portfolio-id-edit___en"
  }, {
    path: "/feeds/categories/:id",
    component: _b0078f14,
    name: "feeds-categories-id___ja___default"
  }, {
    path: "/ja/feeds/categories/:id",
    component: _b0078f14,
    name: "feeds-categories-id___ja"
  }, {
    path: "/en/feeds/categories/:id",
    component: _b0078f14,
    name: "feeds-categories-id___en"
  }, {
    path: "/feeds/senior/:id?",
    component: _6d471fec,
    name: "feeds-senior-id___ja___default"
  }, {
    path: "/ja/feeds/senior/:id?",
    component: _6d471fec,
    name: "feeds-senior-id___ja"
  }, {
    path: "/en/feeds/senior/:id?",
    component: _6d471fec,
    name: "feeds-senior-id___en"
  }, {
    path: "/feeds/sort/:type?",
    component: _1290ded2,
    name: "feeds-sort-type___ja___default"
  }, {
    path: "/ja/feeds/sort/:type?",
    component: _1290ded2,
    name: "feeds-sort-type___ja"
  }, {
    path: "/en/feeds/sort/:type?",
    component: _1290ded2,
    name: "feeds-sort-type___en"
  }, {
    path: "/jobs/internships/:id",
    component: _58ebe062,
    name: "jobs-internships-id___ja___default"
  }, {
    path: "/ja/jobs/internships/:id",
    component: _58ebe062,
    name: "jobs-internships-id___ja"
  }, {
    path: "/en/jobs/internships/:id",
    component: _58ebe062,
    name: "jobs-internships-id___en"
  }, {
    path: "/jobs/seminars/:id",
    component: _07ba3904,
    name: "jobs-seminars-id___ja___default"
  }, {
    path: "/ja/jobs/seminars/:id",
    component: _07ba3904,
    name: "jobs-seminars-id___ja"
  }, {
    path: "/en/jobs/seminars/:id",
    component: _07ba3904,
    name: "jobs-seminars-id___en"
  }, {
    path: "/jobs/show_corp/:id?",
    component: _0f5e73da,
    name: "jobs-show_corp-id___ja___default"
  }, {
    path: "/ja/jobs/show_corp/:id?",
    component: _0f5e73da,
    name: "jobs-show_corp-id___ja"
  }, {
    path: "/en/jobs/show_corp/:id?",
    component: _0f5e73da,
    name: "jobs-show_corp-id___en"
  }, {
    path: "/my_page/offers/:id",
    component: _b0192b32,
    name: "my_page-offers-id___ja___default"
  }, {
    path: "/ja/my_page/offers/:id",
    component: _b0192b32,
    name: "my_page-offers-id___ja"
  }, {
    path: "/en/my_page/offers/:id",
    component: _b0192b32,
    name: "my_page-offers-id___en"
  }, {
    path: "/jobs/internships/:id/entry",
    component: _31cdc122,
    name: "jobs-internships-id-entry___ja___default"
  }, {
    path: "/ja/jobs/internships/:id/entry",
    component: _31cdc122,
    name: "jobs-internships-id-entry___ja"
  }, {
    path: "/en/jobs/internships/:id/entry",
    component: _31cdc122,
    name: "jobs-internships-id-entry___en"
  }, {
    path: "/jobs/seminars/:id/entry",
    component: _1b4948a4,
    name: "jobs-seminars-id-entry___ja___default"
  }, {
    path: "/ja/jobs/seminars/:id/entry",
    component: _1b4948a4,
    name: "jobs-seminars-id-entry___ja"
  }, {
    path: "/en/jobs/seminars/:id/entry",
    component: _1b4948a4,
    name: "jobs-seminars-id-entry___en"
  }, {
    path: "/corporations/:id?",
    component: _28d1bcea,
    name: "corporations-id___ja___default",
    children: [{
      path: "feeds",
      component: _3007bfde,
      name: "corporations-id-feeds___ja___default"
    }, {
      path: "jobs",
      component: _7184c60a,
      name: "corporations-id-jobs___ja___default"
    }, {
      path: "members",
      component: _4d3e6f95,
      name: "corporations-id-members___ja___default"
    }]
  }, {
    path: "/ja/corporations/:id?",
    component: _28d1bcea,
    name: "corporations-id___ja",
    children: [{
      path: "feeds",
      component: _3007bfde,
      name: "corporations-id-feeds___ja"
    }, {
      path: "jobs",
      component: _7184c60a,
      name: "corporations-id-jobs___ja"
    }, {
      path: "members",
      component: _4d3e6f95,
      name: "corporations-id-members___ja"
    }]
  }, {
    path: "/en/corporations/:id?",
    component: _28d1bcea,
    name: "corporations-id___en",
    children: [{
      path: "feeds",
      component: _3007bfde,
      name: "corporations-id-feeds___en"
    }, {
      path: "jobs",
      component: _7184c60a,
      name: "corporations-id-jobs___en"
    }, {
      path: "members",
      component: _4d3e6f95,
      name: "corporations-id-members___en"
    }]
  }, {
    path: "/feeds/:id",
    component: _177574ca,
    name: "feeds-id___ja___default"
  }, {
    path: "/ja/feeds/:id",
    component: _177574ca,
    name: "feeds-id___ja"
  }, {
    path: "/en/feeds/:id",
    component: _177574ca,
    name: "feeds-id___en"
  }, {
    path: "/jobs/:id",
    component: _ee45f70e,
    name: "jobs-id___ja___default"
  }, {
    path: "/ja/jobs/:id",
    component: _ee45f70e,
    name: "jobs-id___ja"
  }, {
    path: "/en/jobs/:id",
    component: _ee45f70e,
    name: "jobs-id___en"
  }, {
    path: "/my_page/:id?",
    component: _1b434e88,
    name: "my_page-id___ja___default",
    children: [{
      path: "bookmarks",
      component: _9ce23b94,
      name: "my_page-id-bookmarks___ja___default"
    }, {
      path: "notice_boards",
      component: _0eefb5cd,
      name: "my_page-id-notice_boards___ja___default"
    }, {
      path: "offers",
      component: _5533282e,
      name: "my_page-id-offers___ja___default"
    }, {
      path: "profile",
      component: _12ad4822,
      name: "my_page-id-profile___ja___default"
    }]
  }, {
    path: "/ja/my_page/:id?",
    component: _1b434e88,
    name: "my_page-id___ja",
    children: [{
      path: "bookmarks",
      component: _9ce23b94,
      name: "my_page-id-bookmarks___ja"
    }, {
      path: "notice_boards",
      component: _0eefb5cd,
      name: "my_page-id-notice_boards___ja"
    }, {
      path: "offers",
      component: _5533282e,
      name: "my_page-id-offers___ja"
    }, {
      path: "profile",
      component: _12ad4822,
      name: "my_page-id-profile___ja"
    }]
  }, {
    path: "/en/my_page/:id?",
    component: _1b434e88,
    name: "my_page-id___en",
    children: [{
      path: "bookmarks",
      component: _9ce23b94,
      name: "my_page-id-bookmarks___en"
    }, {
      path: "notice_boards",
      component: _0eefb5cd,
      name: "my_page-id-notice_boards___en"
    }, {
      path: "offers",
      component: _5533282e,
      name: "my_page-id-offers___en"
    }, {
      path: "profile",
      component: _12ad4822,
      name: "my_page-id-profile___en"
    }]
  }, {
    path: "/recruit/:page_",
    component: _77fddf9a,
    name: "recruit-page____ja___default"
  }, {
    path: "/ja/recruit/:page_",
    component: _77fddf9a,
    name: "recruit-page____ja"
  }, {
    path: "/en/recruit/:page_",
    component: _77fddf9a,
    name: "recruit-page____en"
  }, {
    path: "/company_visit/:id?/entry",
    component: _24c6f9f2,
    name: "company_visit-id-entry___ja___default"
  }, {
    path: "/ja/company_visit/:id?/entry",
    component: _24c6f9f2,
    name: "company_visit-id-entry___ja"
  }, {
    path: "/en/company_visit/:id?/entry",
    component: _24c6f9f2,
    name: "company_visit-id-entry___en"
  }, {
    path: "/jobs/:id/entry",
    component: _c727d7ce,
    name: "jobs-id-entry___ja___default"
  }, {
    path: "/ja/jobs/:id/entry",
    component: _c727d7ce,
    name: "jobs-id-entry___ja"
  }, {
    path: "/en/jobs/:id/entry",
    component: _c727d7ce,
    name: "jobs-id-entry___en"
  }, {
    path: "/jobs/:id/f",
    component: _b1255ce6,
    name: "jobs-id-f___ja___default"
  }, {
    path: "/ja/jobs/:id/f",
    component: _b1255ce6,
    name: "jobs-id-f___ja"
  }, {
    path: "/en/jobs/:id/f",
    component: _b1255ce6,
    name: "jobs-id-f___en"
  }, {
    path: "/jobs/:id/p",
    component: _b00b86d2,
    name: "jobs-id-p___ja___default"
  }, {
    path: "/ja/jobs/:id/p",
    component: _b00b86d2,
    name: "jobs-id-p___ja"
  }, {
    path: "/en/jobs/:id/p",
    component: _b00b86d2,
    name: "jobs-id-p___en"
  }, {
    path: "/corporations/:corporationId?/graduates/:memberId?",
    component: _68374e3e,
    name: "corporations-corporationId-graduates-memberId___ja___default"
  }, {
    path: "/ja/corporations/:corporationId?/graduates/:memberId?",
    component: _68374e3e,
    name: "corporations-corporationId-graduates-memberId___ja"
  }, {
    path: "/en/corporations/:corporationId?/graduates/:memberId?",
    component: _68374e3e,
    name: "corporations-corporationId-graduates-memberId___en"
  }, {
    path: "/corporations/:corporationId?/members/:memberId?",
    component: _41230ab2,
    name: "corporations-corporationId-members-memberId___ja___default"
  }, {
    path: "/ja/corporations/:corporationId?/members/:memberId?",
    component: _41230ab2,
    name: "corporations-corporationId-members-memberId___ja"
  }, {
    path: "/en/corporations/:corporationId?/members/:memberId?",
    component: _41230ab2,
    name: "corporations-corporationId-members-memberId___en"
  }, {
    path: "/my_page/:notice_boards?/:id?",
    component: _e831ae3c,
    name: "my_page-notice_boards-id___ja___default"
  }, {
    path: "/ja/my_page/:notice_boards?/:id?",
    component: _e831ae3c,
    name: "my_page-notice_boards-id___ja"
  }, {
    path: "/en/my_page/:notice_boards?/:id?",
    component: _e831ae3c,
    name: "my_page-notice_boards-id___en"
  }, {
    path: "/recruit/:page_?/:id",
    component: _30d30082,
    name: "recruit-page_-id___ja___default"
  }, {
    path: "/ja/recruit/:page_?/:id",
    component: _30d30082,
    name: "recruit-page_-id___ja"
  }, {
    path: "/en/recruit/:page_?/:id",
    component: _30d30082,
    name: "recruit-page_-id___en"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index___ja___default"
  }, {
    path: "/ja",
    component: _27eb63ad,
    name: "index___ja"
  }, {
    path: "/en",
    component: _27eb63ad,
    name: "index___en"
  }, {
    path: "/:extjobs",
    component: _2632eff0,
    name: "extjobs___ja___default"
  }, {
    path: "/ja/:extjobs",
    component: _2632eff0,
    name: "extjobs___ja"
  }, {
    path: "/en/:extjobs",
    component: _2632eff0,
    name: "extjobs___en"
  }, {
    path: "/:extjobs/:id",
    component: _a6c7a350,
    name: "extjobs-id___ja___default"
  }, {
    path: "/ja/:extjobs/:id",
    component: _a6c7a350,
    name: "extjobs-id___ja"
  }, {
    path: "/en/:extjobs/:id",
    component: _a6c7a350,
    name: "extjobs-id___en"
  }, {
    path: "/favorites",
    redirect: "/my_page/bookmarks",
    name: "bypass_favorites"
  }, {
    path: "/seniors",
    redirect: "/bypass/gone_seniors",
    name: "bypass_seniors"
  }, {
    path: "/sps_list",
    redirect: "/bypass/gone_sps_list",
    name: "bypass_sps_list"
  }, {
    path: "/userdata/diagnosis",
    redirect: "/my_page/profile/aptitude_diagnosis/new",
    name: "bypass_userdata/diagnosis"
  }, {
    path: "/userdata/diagnosis/:id",
    component: _dfe8852a,
    name: "bypass_userdata/diagnosis/id___ja___default"
  }, {
    path: "/ja/userdata/diagnosis/:id",
    component: _dfe8852a,
    name: "bypass_userdata/diagnosis/id___ja"
  }, {
    path: "/en/userdata/diagnosis/:id",
    component: _dfe8852a,
    name: "bypass_userdata/diagnosis/id___en"
  }, {
    path: "/user_policy",
    redirect: "/settings/terms_of_use",
    name: "bypass_user_policy"
  }, {
    path: "/privacy_policy",
    redirect: "/settings/privacy_policy",
    name: "bypass_privacy_policy"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
